import { template as template_844d0aa7d10b46e3bd554fd0ff21b4b4 } from "@ember/template-compiler";
const WelcomeHeader = template_844d0aa7d10b46e3bd554fd0ff21b4b4(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
