import { template as template_52f63b451ecb481881a625ef5d30797c } from "@ember/template-compiler";
const FKLabel = template_52f63b451ecb481881a625ef5d30797c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
