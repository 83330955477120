import { template as template_945a5c2e468441aeae7595aab0120ac2 } from "@ember/template-compiler";
const FKText = template_945a5c2e468441aeae7595aab0120ac2(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
