import { template as template_4f549ed012b44c7b93f06cd521107b65 } from "@ember/template-compiler";
const FKControlMenuContainer = template_4f549ed012b44c7b93f06cd521107b65(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
